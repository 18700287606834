.container {
  display: grid;
  place-content: center;
  margin: 1em;
  font-family: var(--font-montserrat);
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
}

.grid p {
  margin: 0;
  border: 1px solid black;
  margin-top: 1em;
  padding: 0.5em;
}

.row {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.row p {
  border: 1px solid black;
  padding: 1em;
}
