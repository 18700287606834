.container {
  display: flex;
  align-items: start;
  justify-content: center;
  margin-bottom: 10em;
}

.new_left_container {
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.center {
  display: grid;
  place-items: center;
  margin-top: 20px;
  width: 50%;
}

.new_pic1 {
  /* width: 550px;
  height: 300px; */
  width: 500px;
}

.new_pic2 {
  width: 350px;
  margin-right: 20px;
}

.new_pic3 {
  width: 350px;
}

.new_pic4 {
  width: 350px;
  margin-top: 20px;
}

.right_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 60px;
  align-items: center;
  background-image: url("../../../../public/landing/right_image.png");
  background-size: cover;
  color: white;
  height: 800px;
}

@media (max-width: 1500px) {
  .new_pic1 {
  }

  .new_pic2 {
    width: 300px;
    margin: 0;
    margin-right: 10px;
  }

  .new_pic3 {
    width: 300px;
  }

  .new_pic4 {
    width: 300px;
  }
}

@media (max-width: 1300px) {
  .right_container {
    height: 700px;
    gap: 0px;
  }
  .new_pic1 {
    width: 500px;
  }

  .new_pic2 {
    width: 250px;
  }

  .new_pic3 {
    width: 250px;
  }

  .new_pic4 {
    width: 250px;
  }
}

@media (max-width: 940px) {
  .new_pic1 {
    width: 300px;
    height: 200px;
  }

  .new_pic2 {
    width: 200px;
  }

  .new_pic3 {
    width: 200px;
  }

  .new_pic4 {
    width: 200px;
  }
}

@media (max-width: 445px) {
  .new_pic1 {
    width: 300px;
  }

  .new_pic2 {
    width: 150px;
  }

  .new_pic3 {
    width: 150px;
  }

  .new_pic4 {
    width: 150px;
  }
}

.left_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 4em;
  margin: 1.5em 0 0 0;
  font-family: var(--font-open-sans);
  font-weight: 600;
  letter-spacing: 4px;
}

.text {
  padding: 0 4em 0 4em;
  font-size: 1.5em;
  font-family: var(--font-open-sans);
}

.text .text_para {
  text-align: justify;
  padding: 1em 2em 1em 2em;
  /* background-color: rgba(52, 52, 52, 0.665); */
  backdrop-filter: blur(10px);
  border-radius: 20px;
  font-size: 1em;
}

.text2 {
  font-size: 2em;
  padding: 0 4em 0 4em;
  text-align: center;
  color: #fff1a5;
}

.button {
  background-color: #fe6a00;
  border-radius: 25px;
  font-size: 1.5em;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-family: var(--font-open-sans);
  margin-bottom: 1em;
}
.button_a {
  text-decoration: none;
  color: white;
  padding: 15px 30px 15px 30px;
  display: inline-block;
}

.pic1 {
  position: absolute;
  top: 255px;
  left: 400px;
  width: 360px;
}
.pic2 {
  position: absolute;
  top: 255px;
  left: 100px;
  width: 270px;
}
.pic3 {
  position: absolute;
  top: 530px;
  left: 100px;
  width: 350px;
}
.pic4 {
  position: absolute;
  top: 730px;
  left: 460px;
  width: 300px;
}
.group10 {
  position: absolute;
  top: 215px;
  left: 0px;
  width: 40px;
  height: 400px;
}
.group11 {
  position: absolute;
  top: 550px;
  left: 865px;
  width: 75px;
}

@media (max-width: 2210px) {
  .right_container {
    gap: 40px;
  }
}

@media (max-width: 520px) {
  .right_container {
    gap: 0px;
  }
}

@media (max-width: 1590px) {
  .right_container {
    gap: 30px;
  }
}

@media only screen and (max-width: 1700px) {
  .pic1 {
    left: 380px;
    width: 360px;
  }
  .pic2 {
    left: 60px;
    width: 270px;
  }
  .pic3 {
    left: 60px;
    width: 350px;
  }
  .pic4 {
    left: 420px;
    width: 320px;
    top: 715px;
  }
  .group11 {
    left: 800px;
  }
}
@media only screen and (max-width: 1600px) {
  .group11 {
    left: 760px;
  }
  .group10 {
    left: -100px;
  }
}
@media only screen and (max-width: 1550px) {
  .group11 {
    left: -100px;
  }
}

@media only screen and (max-width: 1500px) {
  .pic1 {
    left: 310px;
    width: 360px;
  }
  .pic2 {
    left: 30px;
    width: 260px;
  }
  .pic3 {
    left: 30px;
    width: 350px;
  }
  .pic4 {
    top: 740px;
    left: 390px;
    width: 280px;
  }

  .group10 {
    left: -100px;
  }
}

@media only screen and (max-width: 1355px) {
  .right_container {
    gap: 70px;
  }
  .pic1 {
    position: absolute;
    top: 255px;
    left: 300px;
    width: 260px;
  }
  .pic2 {
    position: absolute;
    top: 255px;
    left: 50px;
    width: 200px;
  }
  .pic3 {
    position: absolute;
    top: 460px;
    left: 50px;
    width: 300px;
  }
  .pic4 {
    position: absolute;
    top: 660px;
    left: 360px;
    width: 205px;
  }
  .title {
    font-size: 2.5em;
  }
  .text {
    font-size: 1.2em;
  }
  .text2 {
    font-size: 1.5em;
  }
  .button {
    font-size: 1em;
  }
  .container {
    margin-bottom: 5em;
  }
}

@media only screen and (max-width: 1145px) {
  .pic1 {
    top: 255px;
    left: 230px;
    width: 200px;
  }
  .pic2 {
    top: 255px;
    left: 50px;
    width: 160px;
  }
  .pic3 {
    top: 420px;
    left: 50px;
    width: 220px;
  }
  .pic4 {
    position: absolute;
    top: 560px;
    left: 280px;
    width: 160px;
  }
  .title {
    font-size: 2em;
  }
  .text {
    font-size: 1em;
  }
  .text2 {
    font-size: 1em;
  }
  .button {
    font-size: 1em;
  }
  .container {
    margin-bottom: 5em;
  }

  .text .text_para {
    padding: 0.5em 1em 0.5em 1em;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6em;
  }
  .center {
    width: 100%;
  }
  .right_container {
    width: 100%;
  }
  .left_container {
    width: 100%;
  }
  .pic1 {
    top: 755px;
    left: 430px;
    width: 200px;
  }
  .pic2 {
    top: 780px;
    left: 250px;
    width: 160px;
  }
  .pic3 {
    top: 950px;
    left: 250px;
    width: 220px;
  }
  .pic4 {
    top: 1090px;
    left: 480px;
    width: 160px;
  }
  .title {
    font-size: 3em;
  }
  .text {
    font-size: 1.2em;
  }
  .text2 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 800px) {
  .pic1 {
    top: 785px;

    left: 390px;
  }
  .pic2 {
    top: 810px;

    left: 200px;
  }
  .pic3 {
    top: 980px;

    left: 200px;
  }
  .pic4 {
    top: 1120px;

    left: 430px;
  }
}

@media only screen and (max-width: 748px) {
  .pic1 {
    top: 820px;

    left: 340px;
  }
  .pic2 {
    top: 820px;

    left: 170px;
  }
  .pic3 {
    top: 990px;

    left: 160px;
  }
  .pic4 {
    top: 1100px;

    left: 390px;
  }
}

@media only screen and (max-width: 689px) {
  .pic1 {
    top: 880px;
  }
  .pic2 {
    top: 880px;
  }
  .pic3 {
    top: 1040px;
  }
  .pic4 {
    top: 1180px;
  }
}

@media only screen and (max-width: 658px) {
  .pic1 {
    top: 920px;
  }
  .pic2 {
    top: 920px;
  }
  .pic3 {
    top: 1080px;
  }
  .pic4 {
    top: 1220px;
  }
}

@media only screen and (max-width: 600px) {
  .pic1 {
    top: 920px;
    left: 260px;
    width: 40%;
  }
  .pic2 {
    top: 920px;
    left: 90px;
    width: 160px;
  }
  .pic3 {
    top: 1080px;
    left: 90px;
    width: 220px;
  }
  .pic4 {
    top: 1220px;
    left: 320px;
    width: 160px;
  }
}

@media (max-width: 500px) {
  .left_container {
    display: none;
  }
  .container {
    margin-bottom: 5em;
  }
  .right_container {
    gap: 30px;
  }
}
