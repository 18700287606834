.container {
  border: 3.5px solid #909090;
  padding: 1rem;
  height: 15px;
  display: grid;
  place-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logo {
  width: 180px;
  height: 100%;
  display: grid;
  place-content: center;
}

.title {
  font-size: var(--font-size-6xl);
  color: var(--color-gray-200);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-4xl);
  font-weight: normal;
  font-family: var(--font-mplus-1p);
  margin: 0;
}

/* OLD STYLES */

.aParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.32px;
  height: 38.02px;
  top: 29px;
  left: 27px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 38px;
  font-size: var(--font-size-4xl);
  color: var(--color-gray-200);
  font-family: var(--font-mplus-1p);
}

.a {
  position: absolute;
  top: 1px;
  left: 11px;
  font-weight: 300;
  display: inline-block;
  width: 17.18px;
  height: 35.02px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.groupChild {
  position: absolute;
  top: -0.18px;
  left: -0.01px;
  width: 38.01px;
  height: 2.18px;
}

.groupInner,
.groupItem {
  position: absolute;
  box-sizing: border-box;
}
.groupItem {
  top: 37px;
  left: 0;
  border-top: 2px solid var(--color-gray-200);
  width: 39px;
  height: 2px;
}

.lineIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.32px;
  height: 38.02px;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.32px;
  height: 38.02px;
  border: 2px solid var(--color-gray-200);
  border-radius: 2px;
}

.link {
  text-decoration: none;
}
