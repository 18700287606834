.container {
  margin-top: 5em;
  margin-bottom: 5em;
  font-family: var(--font-montserrat);
}

.header {
  background: rgba(38, 164, 142, 0.5);
  padding: 0.2px;
  width: 40%;
  height: 100%;
}

.header h2 {
  font-size: var(--font-size-6xl);
  line-height: 1;
  text-align: left;
  margin-left: 0.8em;
  font-family: var(--font-old-standard-tt);
  font-weight: normal;
}

.heading h2 {
  margin: 1em;
}

.imgContainer {
  margin: 1em;
  display: grid;
  place-items: center;
}

.imgCraftman {
  height: 500px;
}

.imgCanvas {
  width: 60%;
}

.desc {
  margin: 1em;
  margin-left: 1.5em;
  font-size: var(--font-size-3xl);
}

.nav {
  margin-top: 20px;
  display: grid;
  place-items: center;
}

.line {
  border-bottom: 2px solid var(--color-gray-200);
  width: 90%;
  margin-top: 30px;
}

.nav a {
  color: #000;
  font-style: normal;
  font-family: var(--font-open-sans);
  font-size: var(--font-size-lg);
  margin: 0.8em;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 10px;
}

.nav a:hover {
  color: var(--color-gray-800);
  text-decoration: underline;
  font-size: var(--font-size-xl);
  margin-left: 1em;
  margin-right: 1em;
}

.nav h2 {
  font-size: var(--font-size-4xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-5xl);
  font-weight: 500;
  line-height: 0;
}
@media (max-width: 760px) {
  .imgCanvas {
    width: 80%;
  }
}

@media (max-width: 950px) {
  .header {
    width: 60%;
  }
}

@media (max-width: 540px) {
  .nav a {
    font-size: 14px;
    margin: 0.2em;
  }
  .nav span {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 690px) {
  .imgCraftman {
    height: 350px;
  }
  .header {
    width: 80%;
  }
  .heading h2 {
    margin: 0.5em;
  }
}

@media (max-width: 490px) {
  .imgCraftman {
    width: 100%;
    height: 100%;
  }
  .header {
    width: 80%;
  }
  .header h2 {
    font-size: var(--font-size-5xl);
    line-height: 1;
    text-align: left;
    margin-left: 0.8em;
    font-family: var(--font-old-standard-tt);
    font-weight: normal;
  }
}
