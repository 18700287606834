.container {
  margin-bottom: 100px;
}

.fieldName {
  background-color: RGB(198, 231, 224);
  padding: 0.5em;
  width: 150px;
  padding-left: 3em;
  padding-right: 3em;
  font-family: var(--font-open-sans);
  margin: 0;
  text-align: center;
}

.fieldValue {
  border-left: 2px solid RGB(191, 190, 191);
}

.fieldValue p {
  font-family: var(--font-open-sans);
  margin: 0;
  margin-left: 2em;
  margin-top: 3px;
  width: 12em;
  height: 100px;
}

.fieldValue span {
  display: block;
}

.info {
  display: flex;
  align-items: start;
}

@media (max-width: 600px) {
  .fieldValue p {
    font-size: 12px;
  }
  .fieldName {
    font-size: 12px;
    width: 50px;
  }
}
