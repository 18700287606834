.container {
  position: relative;
}

.img {
  height: 100%;
  width: 100%;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}

.content {
  width: 50%;
  text-align: center;
  color: #fff;
  font-family: var(--font-open-sans);
  font-weight: 300;
}

.heading {
  font-size: var(--font-size-3xl);
  font-family: var(--font-open-sans);
  font-weight: 600;
  color: #fff;
  font-style: italic;
}

.textField {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.textField input {
  width: 200px;
  height: 2em;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em;
  padding-left: 2em;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61) 0%,
    rgba(237, 187, 136, 0.67) 100%
  );
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.textField input:focus {
  outline: none;
}

.textField span {
  padding: 10px;
  background: rgba(237, 187, 136, 0.76);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-left: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  height: 1.2em;
}

@media screen and (max-width: 860px) {
  .heading {
    font-size: var(--font-size-xl);
  }
  .textField input {
    width: 200px;
    height: 1.2em;
    font-size: 12px;
  }
  .textField span {
    width: 10px;
    height: 10px;
    padding: 8px;
  }
}

@media screen and (max-width: 860px) {
  .heading {
    font-size: 12px;
  }
  .box {
    transform: translate(-50%, -60%);
  }
}
