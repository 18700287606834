.container {
  display: flex;
  justify-content: start;
  margin-bottom: 8em;
}

.header {
  background: rgba(38, 164, 142, 0.5);
  padding: 0px;
  height: 100%;
  width: 35%;
  margin-right: 10px;
}

.header h2 {
  font-size: var(--font-size-6xl);
  line-height: 0;
  text-align: left;
  margin-left: 1em;
  font-family: var(--font-old-standard-tt);
  font-weight: normal;
}

.content {
  position: relative;
}
.vector {
  width: 750px;
  height: 425px;
}

.fields li {
  font-family: var(--font-open-sans);
  margin-bottom: 0.4em;
  font-size: var(--font-size-4xl);
}

.secondary {
  /* margin-inline: 2em; */
  display: flex;
  width: 84%;
  box-shadow: inset 3px 3px 9px #d0d0d0, inset -3px -3px 3px #f8f8f8;
  padding: 10px;
  border-radius: 10px;
}

.imgContainer {
  display: grid;
  place-items: center;
  border-radius: 10px;
  /* padding-bottom: 40px; */
  /* margin-left: 30px; */
  margin-right: 30px;
}

.imgContainer img {
  align-self: center;
  width: 200px;
  /* margin: 10px; */
  border-radius: 50%;
}

.secondaryContent {
  box-shadow: 3px 3px 9px #d0d0d0, -3px -3px 3px #f8f8f8;
  border-radius: 10px;
  padding: 4em 3em;
  text-align: center;
  line-height: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondaryContent p {
  font-family: var(--font-montserrat);
  font-size: var(--font-size-xl);
  line-height: 1.5em;
}

.secondaryContent_1 {
  background: rgba(38, 164, 142, 0.5);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 3em;
  padding-left: 10em;
  padding-right: 10em;
  margin-right: 20px;
}

.secondaryContent li {
  font-family: var(--font-open-sans);
  font-size: var(--font-size-3xl);
  line-height: 1.5em;
  margin-bottom: 1em;
}

.secondaryContent_1 li {
  font-family: var(--font-open-sans);
  font-size: var(--font-size-3xl);
  line-height: 1.5em;
}

.btnCont {
  display: flex;
  justify-content: center;
}

.btn {
  background: none;
  border: none;
}

.review {
  font-size: var(--font-size-xl);
  margin: 0;
  line-height: 1.2;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  width: 100%;
}

.arrow {
  flex-shrink: 0;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0.25em;
  margin-inline: 2em;
}

@media (max-width: 1024px) {
  .vector {
    width: 550px;
    height: 225px;
  }
  .fields li {
    font-size: var(--font-size-xl);
  }
  .secondaryContent_1 {
    padding-left: 5em;
    padding-right: 5em;
  }
}

@media (max-width: 950px) {
  .container {
    flex-direction: column;
  }
  .secondaryContent_1 {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 840px) {
  .imgContainer {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }
  .imgContainer img {
    width: 120px;
  }
  .secondaryContent p {
    font-size: 12px;
  }
  .secondaryContent {
    flex-direction: column;
    padding: 1em;
  }
}

@media (max-width: 640px) {
  .secondaryContent_1 {
    flex-direction: column;
    text-align: center;
    padding: 1em;
  }
}
