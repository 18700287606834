.container {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #26a48e;
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  text-decoration: none;
}

.links {
  text-align: center;
}

.links a {
  color: white;
  text-decoration: none;
  margin: 3rem;
  line-height: 50px;
  font-family: var(--font-montserrat);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
}

.socials {
  margin-right: 1em;
}

.socials p {
  color: white;
  line-height: 0;
}

.socials span {
  display: flex;
  justify-content: center;
}

.footer img {
  width: 200px;
}

.footer {
  margin: 1em;
  display: grid;
  place-items: center;
}

.footer h1 {
  font-size: var(--font-size-4xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-4xl);
  font-weight: normal;
  margin: 0;
  margin-top: 10px;
}

.text {
  font-size: var(--font-size-xl);
  font-family: var(--font-montserrat);
  color: var(--color-gray-800);
  font-weight: normal;
  line-height: 0;
  margin-top: 8px;
}

.Logocontainer {
  border: 3.5px solid #fff;
  padding: 1rem;
  height: 15px;
  display: grid;
  place-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Logotitle {
  font-size: var(--font-size-4xl);
  color: #fff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-4xl);
  font-weight: normal;
  font-family: var(--font-mplus-1p);
  margin: 0;
}

@media only screen and (max-width: 780px) {
  .links a {
    font-size: 15px;
  }
  .socials p {
    display: none;
  }
  .Logocontainer {
    display: none;
  }
  .box {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .links a {
    margin: 1rem;
  }
  .footer {
    margin: 1em;
  }
  .footer h1 {
    font-size: var(--font-size-xl);
    text-align: center;
  }
  .text {
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }
}
