.header {
  margin-top: 10px;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
}

.line {
  border-bottom: 2px solid var(--color-gray-200);
  width: 90%;
}

.header a {
  color: #000;
  font-style: normal;
  font-family: var(--font-open-sans);
  font-size: var(--font-size-lg);
  margin-left: 0.8em;
  margin-right: 0.8em;
  text-decoration: none;
}

.header h2 {
  font-size: var(--font-size-4xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-5xl);
  font-weight: 500;
  line-height: 0;
}

.body {
  display: flex;
  margin-top: 4em;
  margin-bottom: 4em;
}

.options {
  padding: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #26a48e;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.options a {
  font-family: var(--font-montserrat);
  font-size: 22px;
  line-height: 3em;
  text-decoration: none;
  color: #fff;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
  font-weight: 500;
}

.options a:hover {
  /* border: 1px solid #000; */
  /* padding-left: 0.5em;
  padding-right: 0.5em; */
  /* font-size: var(--font-size-lg); */
  scale: 1.1;
}

.imgContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 5em;
  grid-column-gap: 10em;
  margin-left: 6em;
}

.imgContainer p {
  text-align: center;
  font-family: var(--font-open-sans);
}

@media (max-width: 1230px) {
  .options a {
    font-size: var(--font-size-lg);
  }
  .options {
    padding: 1em;
  }
}

@media (max-width: 980px) {
  .body {
    flex-direction: column;
  }
  .options {
    text-align: center;
  }
  .options a {
    font-size: 12px;
  }
}
