.book {
}

.imgCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 70%;
}

.close {
  position: absolute;
  top: 0;
  right: 30px;
  margin: 10px;
  font-size: 30px;
  color: #26a48e;
  cursor: pointer;
}

.modal {
  position: absolute;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 107%;
  padding-right: 20px;
}

.modalBody {
  margin: 10px;
  height: 100%;
  width: 30%;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
}

.modal img {
  /* width: 500px; */
  height: 500px;
  margin: 20px;
  object-fit: cover;
}

.page {
  /* position: relative; */
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.animating {
  transform: rotateY(-2deg);
}

.paginations {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.paginations button {
  background: none;
  border: none;
  color: #26a48e;
  font-weight: bold;
  font-size: large;
}

.imgGrid {
  display: grid;
  grid-template-columns: repeat(4, 0.1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
  place-content: center;
}

.imgGrid img {
  width: 250px;
  height: 250px;
}

@media (max-width: 1230px) {
  /* .paginations {
    margin-top: 410px;
  } */
}

@media (max-width: 980px) {
  .book {
    width: 100%;
    height: 100%;
  }
  .paginations {
    margin-top: 0px;
  }
}
