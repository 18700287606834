.header {
  margin-top: 10px;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
}

.line {
  border-bottom: 2px solid var(--color-gray-200);
  width: 90%;
}

.header a {
  color: #000;
  font-style: normal;
  font-family: var(--font-open-sans);
  font-size: var(--font-size-lg);
  margin-left: 0.8em;
  margin-right: 0.8em;
  text-decoration: none;
}

.header h2 {
  font-size: var(--font-size-4xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-5xl);
  font-weight: 500;
  line-height: 0;
}

.switch {
  display: grid;
  place-items: center;
  margin-bottom: 2em;
}

.switch span {
  display: flex;
  align-items: center;
}

.switch p {
  margin-left: 1em;
  margin-right: 1em;
}

.content {
  display: flex;
  justify-content: space-evenly;
}

.mapContainer {
  display: grid;
  place-items: end;
  margin-bottom: 2em;
  margin-left: 2em;
}

.mapContainer iframe {
  width: 600px;
  height: 350px;
}

.card {
  margin-top: 0px;
  background: linear-gradient(0deg, #26a48e, #26a48e),
    linear-gradient(0deg, rgba(38, 164, 142, 0.1), rgba(38, 164, 142, 0.1)),
    rgba(38, 164, 142, 0.1);
  border-radius: 0.5em;
  padding: 0.7em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
  font-family: var(--font-montserrat);
  font-size: var(--font-size-xl);
  color: white;
  text-decoration: none;
  margin: 10px;
  width: 300px;
  text-align: center;
}

.card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card p {
  color: white;
  margin-right: 20px;
  font-weight: 600;
  line-height: 4px;
}

.mapContainer p {
  font-family: var(--font-open-sans);
  font-size: var(--font-size-2xl);
}

@media (max-width: 1215px) {
  .mapContainer iframe {
    width: 400px;
    height: 250px;
  }
}

@media (max-width: 400px) {
  .mapContainer iframe {
    width: 250px;
    height: 200px;
  }
}

@media (max-width: 1000px) {
  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mapContainer iframe {
    margin-top: 20px;
  }
  .mapContainer {
    place-items: center;
  }
  .mapContainer a {
    margin-right: 0;
  }
}
