@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mplus+1p:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-mplus-1p: "Mplus 1p";
  --font-old-standard-tt: "Old Standard TT";
  --font-open-sans: "Open Sans";
  --font-inherit: inherit;

  /* font sizes */
  --font-size-4xs: 8px;
  --font-size-3xs: 9px;
  --font-size-2xs: 10px;
  --font-size-xs: 11px;
  --font-size-sm: 12px;
  --font-size-base: 13px;
  --font-size-lg: 14px;
  --font-size-xl: 15px;
  --font-size-2xl: 16px;
  --font-size-3xl: 18px;
  --font-size-4xl: 24px;
  --font-size-5xl: 32px;
  --font-size-6xl: 40px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #b4b1b5;
  --color-gray-200: #909090;
  --color-gray-300: rgba(255, 255, 255, 0.61);
  --color-gray-400: rgba(0, 0, 0, 0.9);
  --color-gray-500: rgba(0, 0, 0, 0.5);
  --color-gray-600: rgba(0, 0, 0, 0.25);
  --color-gray-700: rgba(0, 0, 0, 0.72);
  --color-gray-800: rgba(0, 0, 0, 0.81);
  --color-gray-900: rgba(0, 0, 0, 0.7);
  --color-teal-100: #26a48e;
  --color-teal-200: rgba(38, 164, 142, 0.4);
  --color-teal-300: rgba(38, 164, 142, 0.7);
  --color-teal-400: rgba(38, 164, 142, 0.13);
  --color-black: #000;
  --color-beige: #bdece4;
  --color-green: #00b23b;
  --color-indigo: #006aff;

  /* Paddings */
  --padding-sm: 10px;
  --padding-md: 24px;

  /* border radiuses */
  --br-xs: 4px;
  --br-sm: 10px;
  --br-md: 20px;
  --br-lg: 50px;
}
