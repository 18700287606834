.container {
  margin-bottom: 6em;
  font-family: var(--font-montserrat);
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  background: rgba(38, 164, 142, 0.5);
  padding: 0px;

  width: 50%;
  height: 100%;
}

.title {
  display: flex;
  justify-content: flex-start;
}

.title h2 {
  font-size: var(--font-size-6xl);
  line-height: 0;
  text-align: left;
  margin-left: 2em;
  font-family: var(--font-old-standard-tt);
  font-weight: normal;
}

.header p {
  font-size: var(--font-size-xl);
  font-family: var(--font-open-sans);
  text-align: right;
  width: 50%;
  margin-right: 4em;
}

/* BODY */

.content {
  display: grid;
  place-content: center;
  position: relative;
}

.row_1 {
  display: flex;
  margin-top: 5em;
}

.field {
  line-height: 0;
  font-weight: 600;
  font-size: 28px;
}

.value,
.value_2 {
  font-size: var(--font-size-xs);
  font-weight: 600;
}

.value {
  margin: 0;
  width: 14em;
}

.row_1 img {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.4));
}

/* FRAME 1 */

.frame_1 {
  display: flex;
}

.frame_1 .text {
  width: 10em;
  text-align: right;
  font-size: var(--font-size-2xl);
  margin-right: 1em;
}

.frame_1 img {
  width: 210px;
}

.arrow_1 img {
  width: 200px;
  margin-top: 5em;
}

.arrow_2 .arrow {
  width: 200px;
  margin-top: 5em;
}

/* Frame 2 */

.frame_2 {
  margin-top: 4em;
}

.frame_2 .child {
  display: flex;
}

.frame_2 .section_1 img {
  width: 220px;
  height: 350px;
}

.frame_2 .section_2 img {
  width: 210px;
}

/* Frame 3 */

.frame_3 {
  position: relative;
  margin-left: -12em;
  margin-top: -10em;
}

.frame_3 .section {
  display: flex;
}

.frame_3 .img {
  width: 250px;
}

.arrow_3 {
  position: absolute;
  right: 420px;
  width: 100%;
  top: 200px;
}

/* Frame 4 */

.frame_4 {
  margin-left: 0em;
  width: fit-content;
}

.frame_4 .child {
  display: flex;
}

.frame_4 img {
  width: 250px;
}

.frame_4 .shift_1 {
  width: 16em;
  text-align: right;
  align-self: end;
  margin-bottom: 1em;
}

.arrow_4 {
  margin-left: 20em;
  margin-top: 1em;
}

.frame_4 .imgSection {
  display: flex;
}

.imgSection .img_1 {
  width: 300px;
}

.arrow_5 {
  margin-left: 18em;
}

/* Frame 5 */

.frame_5 {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: -10em;
}

.frame_5 .img_1 {
  width: 360px;
}

.frame_5 .img_2 {
  width: 200px;
}

.arrow_6 {
  margin-left: 5em;
}

/* Frame 6 */

.frame_6 {
  margin-top: -15em;
  margin-left: 4em;
}

.frame_6 img {
  width: 250px;
}

/* Vector */

.vector_1 {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: -1;
}

.vector_2 {
  position: absolute;
  top: 30em;
  left: 0px;
  z-index: -1;
}

.vector_3 {
  position: absolute;
  bottom: 20em;
  right: 10em;
  z-index: -1;
}

.vector_4 {
  position: absolute;
  bottom: -10em;
  left: 0px;
  z-index: -1;
}

.vector_container img {
}

@media (max-width: 1340px) {
  .frame_3 .section {
    margin-left: -10em;
    margin-top: 8em;
  }
  .frame_2 .child {
    flex-direction: column;
  }
  .arrow_2 .arrow {
    margin-top: -4em;
    margin-left: 14em;
  }
  .arrow_3 img {
    width: 400px;
    margin-top: 4em;
  }
}

@media (max-width: 1180px) {
  .frame_4 .child {
    display: block;
    margin-left: 30px;
  }
  .frame_3 {
    margin-left: -18em;
  }
  .frame_4b {
    margin-left: -10em;
  }
}

@media (max-width: 1040px) {
  .frame_6 img {
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .frame_1 img {
    width: 160px;
  }
  .frame_2 .section_1 img {
    width: 160px;
    height: 200px;
  }
  .frame_2 .section_2 img {
    width: 180px;
  }
  .arrow_2 .arrow {
    width: 150px;
    margin-top: 2em;
  }
  .frame_3 .img {
    width: 180px;
  }
  .frame_3 {
    margin-left: -7em;
  }
  .frame_4 img {
    width: 180px;
  }
  .imgSection .img_1 {
    width: 150px;
  }
  .frame_5 .img_1 {
    width: 200px;
  }
  .frame_5 .img_2 {
    width: 150px;
  }
  .arrow_5 img {
    width: 80px;
    margin-top: -10em;
    margin-bottom: 8em;
    margin-left: 8em;
  }
}

@media (max-width: 1040px) {
  .container {
    display: none;
  }
}
