.container {
  display: flex;
  align-items: start;

  height: 100%;
  font-family: var(--font-montserrat);
}

.left {
  display: flex;
  width: 70%;
  justify-content: center;
}

.imgGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-left: 20px;
}

.imgGridw {
  width: 60%;
  grid-template-rows: 250px 250px;
  grid-column-gap: 20px;
}

.imgGride {
  width: 80%;
  grid-column-gap: 20px;
  grid-template-rows: 500px 500px;
}

.imgGridy {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-top: 50px;
}

.img {
  max-width: 200px;
  max-height: 100%;
  margin: auto;
  box-shadow: 3px 3px 9px #d0d0d0, -3px -3px 3px #f8f8f8;
  border-radius: 0.5em;
}

.vector1 {
  position: absolute;
  bottom: -40px;
  left: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.vector2 {
  position: absolute;
  bottom: -120px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.frame_1 {
  position: absolute;
  height: 230px;
  object-fit: cover;
  left: -10px;
  top: 25px;
  z-index: 5;
}

.vectorIcon_1 {
  width: 413.61px;
}

.frame_2 {
  position: absolute;
  top: 80px;
  left: 200px;
  width: 215px;
  z-index: 2;
}

.vectorIcon_2 {
  position: absolute;
  bottom: 0px;
  right: 55px;
  width: 400.23px;
  height: 333.07px;
}

.right {
  position: relative;
  height: 100%;
  width: 30%;
  margin-right: 50px;
}

.outerBox {
  background-color: rgba(255, 139, 19, 0.5);
  border-radius: var(--br-lg) 0 var(--br-lg) 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  display: grid;
  place-items: center;
}

.innerBox {
  background-color: var(--color-gray-300);
  height: 232px;
  width: 80%;
  border-radius: var(--br-lg) 0 var(--br-lg) 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.content {
  text-align: left;
  font-size: 26px;
  font-weight: 500;
  display: inline-block;
  margin: 25px;
}

.title {
  text-align: center;
  font-size: 31px;
  text-transform: uppercase;
  font-family: var(--font-montserrat);
  color: var(--color-gray-900);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.3em;
  font-weight: 500;
}

@media (max-width: 1410px) {
  .title {
    font-size: var(--font-size-4xl);
  }
  /* .outerBox {
    width: 400px;
  } */
  .content {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .img {
    max-width: 250px;
  }
}

@media (min-width: 1600px) {
  .img {
    max-width: 300px;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: var(--font-size-2xl);
  }

  .innerBox {
    height: 150px;
  }
}

@media (max-width: 1230px) {
  /* .container {
    flex-direction: column;
  }
  .right {
    margin-top: 110px;
  } */

  .imgGridw {
    grid-template-rows: 200px 200px;
  }
}

.text {
  text-align: center;
  line-height: 0;
}

@media (max-width: 980px) {
  .content {
    font-size: 12px;
  }
}

@media (max-width: 750px) {
  .img {
    max-width: 150px;
  }
  .outerBox {
    width: 100%;
  }
  .right {
    width: 70%;
    margin-bottom: 20px;
  }
  .left {
    width: 100%;
  }
}

@media (max-width: 2560px) {
  .img {
    max-width: 550px;
  }
}

@media (max-width: 2145px) {
  .img {
    max-width: 450px;
  }
}

@media (max-width: 1845px) {
  .img {
    max-width: 350px;
  }
}

@media (max-width: 1545px) {
  .img {
    max-width: 250px;
  }
}

@media (max-width: 1245px) {
  .img {
    max-width: 150px;
  }
  .outerBox {
    width: 300px;
  }
  .title {
    width: 300px;
  }
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
