.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1em 0.5em 1em;
}

.title {
  font-size: var(--font-size-6xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: normal;
}

.searchIcon {
  width: 18px;
  height: 18px;
}

@media (max-width: 740px) {
  .title {
    font-size: var(--font-size-3xl);
  }
}
