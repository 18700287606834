.container {
  background: #fe6a00;
  display: grid;
  place-content: end;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-family: var(--font-old-standard-tt);
  font-weight: 500;
  letter-spacing: 1px;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: end;
}

.btn {
  cursor: pointer;
  background-color: transparent;
  font-size: var(--font-size-lg);
  font-weight: 300;
  font-family: var(--font-open-sans);
  color: #fff;
  text-align: left;
  display: inline-block;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
  margin-left: 2rem;
  text-decoration: none;
  padding: 0.2rem;
  border: 1px solid transparent;
  font-weight: 500;
  letter-spacing: 1px;
}

.btn:hover {
  /* border: 1px solid #000; */
  scale: 1.1;
}

.wrapper {
  padding: var(--padding-sm);
}

.appBtn {
  text-decoration: none;
  color: #000;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
  background: linear-gradient(0deg, #26a48e, #26a48e),
    linear-gradient(0deg, rgba(38, 164, 142, 0.1), rgba(38, 164, 142, 0.1)),
    rgba(38, 164, 142, 0.1);
  padding: 0.7em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  border-radius: 0.5em;
  color: white;
  font-family: var(--font-open-sans);
  font-size: var(--font-size-xs);
  margin-right: 20px;
}

.appBtn:hover {
  scale: 1.1;
}

@media screen and (max-width: 670px) {
  .items {
    flex-direction: column;
    align-self: center;
  }
  .container {
    place-content: center;
  }
  .btn {
    margin: 0;
    align-self: center;
    text-align: center;
  }
  .appBtn {
    margin-right: 0;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
