.desktop15Child {
  background-color: red;
  width: 100%;
  height: 89px;
}
.artisticPictureFraming {
  position: absolute;
  top: 41px;
  left: 564px;
  font-size: var(--font-size-4xl);
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  display: inline-block;
  width: 362px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.vectorIcon {
  position: absolute;
  top: 336.08px;
  left: 266.91px;
  width: 400.23px;
  height: 333.07px;
}
.desktop15Item,
.vectorIcon1 {
  position: absolute;
  top: 787.78px;
  left: 1017.76px;
  width: 334.37px;
  height: 348.2px;
}
.desktop15Item {
  top: 1876.5px;
  left: -158px;
  background: linear-gradient(
    180deg,
    rgba(250, 235, 217, 0.34),
    rgba(38, 164, 142, 0.15) 0.01%,
    rgba(255, 255, 255, 0.34)
  );
  width: 140px;
  height: 554px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.desktop15Inner {
  top: 327px;
  left: 738px;
  background-color: rgba(38, 164, 142, 0.12);
  width: 638px;
  height: 300px;
}
.desktop15Child1,
.desktop15Child2,
.desktop15Inner,
.rectangleDiv {
  position: absolute;
  border-radius: var(--br-lg) 0 var(--br-lg) 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.rectangleDiv {
  top: 798px;
  left: 78px;
  background-color: rgba(228, 135, 32, 0.12);
  width: 638px;
  height: 300px;
}
.desktop15Child1,
.desktop15Child2 {
  top: 359px;
  left: 795px;
  background-color: var(--color-gray-300);
  width: 518px;
  height: 232px;
}
.desktop15Child2 {
  top: 830px;
  left: 135px;
}
.frameChild {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(38, 164, 142, 0.17),
    rgba(228, 135, 32, 0.17)
  );
  width: 1440px;
  height: 45px;
  flex-shrink: 0;
}
.frameDiv {
  position: absolute;
  top: 83px;
  left: -10px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.loremIpsumDolorSitAmetCo,
.loremIpsumDolorSitAmetCo1 {
  position: absolute;
  font-size: var(--font-size-base);
  font-weight: 300;
  display: inline-block;
  width: 423px;
}
.loremIpsumDolorSitAmetCo {
  top: 387px;
  left: 852px;
  text-align: right;
}
.loremIpsumDolorSitAmetCo1 {
  top: 963px;
  left: 164px;
}
.vectorIcon2 {
  position: absolute;
  top: 170.76px;
  left: 62.69px;
  width: 413.61px;
  height: 487.2px;
}
.vectorIcon3 {
  top: 642.71px;
  left: 779.9px;
  width: 403.9px;
  height: 467.36px;
}
.a,
.groupChild,
.vectorIcon3 {
  position: absolute;
}
.a {
  top: 1px;
  left: 11px;
  font-weight: 300;
  display: inline-block;
  width: 17.18px;
  height: 35.02px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.groupChild {
  top: -0.18px;
  left: -0.01px;
  width: 38.01px;
  height: 2.18px;
}
.groupInner,
.groupItem {
  position: absolute;
  box-sizing: border-box;
}
.groupItem {
  top: 37px;
  left: 0;
  border-top: 2px solid var(--color-gray-200);
  width: 39px;
  height: 2px;
}
.groupInner {
  top: 0;
  left: 37px;
  border-right: 2px solid var(--color-gray-200);
  width: 2px;
  height: 39px;
}
.aParent,
.lineIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.32px;
  height: 38.02px;
}
.aParent {
  top: 29px;
  left: 27px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 38px;
  font-size: var(--font-size-4xl);
  color: var(--color-gray-200);
  font-family: var(--font-mplus-1p);
}
.searchIcon {
  position: absolute;
  top: 41px;
  left: 1360px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.gallery {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 300;
  font-family: var(--font-open-sans);
  color: var(--color-black);
  text-align: left;
  display: inline-block;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
}
.contactUsWrapper,
.galleryWrapper {
  position: absolute;
  top: 96px;
  left: 979px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.contactUsWrapper {
  left: 1299px;
}
.desktop15Child3 {
  position: absolute;
  top: 1218px;
  left: 195px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.11),
    rgba(38, 164, 142, 0)
  );
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 1032px;
  height: 562px;
}
.scheduleAMeetingWrapper {
  position: absolute;
  top: 96px;
  left: 1110px;
  width: 165px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.image53Icon {
  position: absolute;
  top: 278px;
  left: 323px;
  width: 275px;
  height: 363px;
  object-fit: cover;
}
.letsGetTheInnovativeFrames,
.letsGetTheInnovativeFrames1 {
  position: absolute;
  font-size: var(--font-size-6xl);
  text-transform: uppercase;
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-900);
  display: inline-block;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.letsGetTheInnovativeFrames {
  top: 275px;
  left: 715px;
  text-align: right;
  width: 676px;
}
.letsGetTheInnovativeFrames1 {
  top: 746px;
  left: 65px;
  width: 685px;
}
.desktop15Child4 {
  position: absolute;
  top: 4051.5px;
  left: -107px;
  background: linear-gradient(
    180deg,
    rgba(250, 235, 217, 0.34),
    rgba(38, 164, 142, 0.15) 0.01%,
    rgba(255, 255, 255, 0.34)
  );
  width: 140px;
  height: 554px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.framing {
  font-size: var(--font-size-5xl);
}
.customFraming,
.subscribe {
  position: absolute;
  top: 1906.5px;
  left: 86px;
  text-transform: uppercase;
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-900);
  text-align: right;
  display: inline-block;
  width: 277px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-6xl);
}
.subscribe {
  top: 4995px;
  left: 510px;
}
.rectangleIcon {
  position: absolute;
  top: 1243.5px;
  left: 218px;
  width: 981px;
  height: 476px;
  object-fit: cover;
}
.groupIcon {
  position: absolute;
  top: 1732.5px;
  left: 658px;
  width: 87px;
  height: 23px;
}
.desktop15Child5,
.desktop15Child6 {
  position: absolute;
  top: 1173.11px;
  left: 183.11px;
  width: 1049.82px;
  height: 5.77px;
}
.desktop15Child6 {
  top: 1819.11px;
}
.vectorIcon4,
.vectorIcon5,
.vectorIcon6 {
  position: absolute;
  top: 2432.5px;
  left: 0;
  width: 782px;
  height: 660px;
}
.vectorIcon5,
.vectorIcon6 {
  top: 2999.5px;
  left: 499px;
}
.vectorIcon6 {
  top: 1906.5px;
  left: 1003px;
  width: 767px;
  height: 879px;
}
.image7Icon,
.vectorIcon7 {
  position: absolute;
  top: 3275.78px;
  left: 0;
  width: 290.27px;
  height: 846.83px;
}
.image7Icon {
  top: -15px;
  left: -15px;
  width: 550px;
  height: 306px;
  object-fit: cover;
}
.image58Icon,
.image7Wrapper {
  position: absolute;
  top: 1343.5px;
  left: 1270px;
  width: 550px;
  height: 276px;
}
.image58Icon {
  top: 1346px;
  left: 0;
  height: 306px;
  object-fit: cover;
}
.loremIpsumDolorSitAmetCo2 {
  position: absolute;
  top: 1917.5px;
  left: 570px;
  font-size: var(--font-size-lg);
  text-align: right;
  display: inline-block;
  width: 759px;
}
.vectorIcon8 {
  position: absolute;
  top: 4001.5px;
  left: 490px;
  width: 750px;
  height: 425px;
  object-fit: cover;
}
.loremIpsumDolor {
  margin-bottom: 0;
}
.loremIpsumDolorSitAmetC {
  margin: 0;
  padding-left: var(--padding-md);
}
.loremIpsumDolorSitAmetCo3,
.loremIpsumDolorSitAmetCo4 {
  position: absolute;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 492px;
}
.loremIpsumDolorSitAmetCo3 {
  top: 4112.5px;
  left: 644px;
  font-weight: 600;
  color: var(--color-white);
}
.loremIpsumDolorSitAmetCo4 {
  top: 4585px;
  left: 621px;
  color: rgba(0, 0, 0, 0.47);
}
.desktop15Child7 {
  position: absolute;
  top: 2129px;
  left: 434px;
  width: 179.5px;
  height: 148.83px;
}
.desktop15Child8,
.desktop15Child9 {
  position: absolute;
  top: 2666px;
  left: 568.5px;
  width: 576.5px;
  height: 136px;
}
.desktop15Child9 {
  top: 2923px;
  left: 482.5px;
  width: 295.5px;
  height: 123.47px;
}
.desktop15Child10 {
  position: absolute;
  top: 3607.5px;
  left: 459.35px;
  width: 444.15px;
  height: 129.77px;
}
.desktop15Child11 {
  position: absolute;
  top: 3227px;
  left: 1178.5px;
  width: 117.26px;
  height: 267px;
}
.desktop15Child12 {
  position: absolute;
  top: 2164.3px;
  left: 974.5px;
  width: 246.79px;
  height: 213.7px;
}
.addingMat {
  position: absolute;
  top: 2087.5px;
  left: 96px;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  width: 140px;
}
.mounting {
  top: 2105.5px;
  left: 621px;
  width: 140px;
}
.cutTheMoulding,
.cuttingTheGlass,
.join,
.mounting {
  position: absolute;
  font-weight: 600;
  display: inline-block;
}
.cuttingTheGlass {
  top: 2372.5px;
  left: 1003px;
  text-align: right;
  width: 96px;
}
.cutTheMoulding,
.join {
  top: 2610.5px;
  left: 566px;
  width: 140px;
}
.join {
  top: 2923.5px;
  left: 787px;
}
.fitting {
  top: 3358.5px;
  left: 918px;
  font-weight: 600;
}
.finishing,
.fitting,
.theMatInCustomFramingServ {
  position: absolute;
  display: inline-block;
  width: 140px;
}
.finishing {
  top: 3302px;
  left: 455px;
  font-weight: 600;
}
.theMatInCustomFramingServ {
  top: 2114.5px;
  left: 96px;
  font-size: var(--font-size-2xs);
  text-align: right;
}
.itIsTheArtOrProcessOfCu,
.itIsTheMethodUsedForHold,
.theMatProvidesImportantPra {
  position: absolute;
  top: 2388.5px;
  left: 250px;
  font-size: var(--font-size-2xs);
  display: inline-block;
  width: 140px;
}
.itIsTheArtOrProcessOfCu,
.itIsTheMethodUsedForHold {
  top: 2241.5px;
  left: 832px;
}
.itIsTheArtOrProcessOfCu {
  top: 2659.5px;
  left: 1171px;
  text-align: right;
}
.itsFourPiecesOfBaseCapM,
.joinThePictureFrameTogethe,
.theFoamCoreIsFittedUsing {
  position: absolute;
  top: 3221.5px;
  left: 786px;
  font-size: var(--font-size-2xs);
  display: inline-block;
  width: 202px;
}
.itsFourPiecesOfBaseCapM,
.theFoamCoreIsFittedUsing {
  top: 3726.5px;
  left: 918px;
}
.itsFourPiecesOfBaseCapM {
  top: 2837.5px;
  left: 192px;
  text-align: right;
  width: 140px;
}
.our,
.services {
  position: absolute;
  top: 4122.5px;
  left: 105px;
  font-size: var(--font-size-6xl);
  text-transform: uppercase;
  font-family: var(--font-old-standard-tt);
  display: inline-block;
  width: 238px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.our {
  top: 4091.5px;
  font-size: var(--font-size-5xl);
  width: 217px;
}
.desktop15Child13 {
  position: absolute;
  top: 4912px;
  left: 0;
  border-radius: 40px;
  width: 1785px;
  height: 348px;
  object-fit: cover;
}
.getAllTheUpdatesAndExclus {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-weight: 600;
  width: 460px;
  height: 25px;
}
.groupChild1,
.groupChild2 {
  position: absolute;
  top: 36.53px;
  border-radius: var(--br-md);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  height: 41.09px;
}
.groupChild1 {
  left: 97.16px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61),
    rgba(237, 187, 136, 0.67)
  );
  width: 323.68px;
}
.groupChild2 {
  left: 428.89px;
  background-color: rgba(237, 187, 136, 0.76);
  width: 41.28px;
}
.email {
  position: absolute;
  top: 47.06px;
  left: 118.3px;
  font-size: var(--font-size-2xl);
  display: inline-block;
  font-family: var(--font-montserrat);
  color: rgba(0, 0, 0, 0.53);
  text-align: left;
  width: 302.54px;
  height: 18.54px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.vectorIcon9 {
  position: absolute;
  height: 19.08%;
  width: 2.36%;
  top: 63.12%;
  right: 3.21%;
  bottom: 17.8%;
  left: 94.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.getAllTheUpdatesAndExclusParent {
  position: absolute;
  top: 5082px;
  left: 445px;
  width: 470.17px;
  height: 77.63px;
  text-align: right;
  font-size: 20px;
  color: var(--color-white);
}
.cumAeneanLeo {
  margin-block-start: 0;
  margin-block-end: 0;
}
.aliquamHendrerit {
  margin: 0;
}
.loremIpsumDolorSitAmetCo5 {
  position: absolute;
  top: 4988px;
  left: 5px;
  font-weight: 300;
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  width: 1435px;
  height: 73px;
}
.abef55e86ff46cc81095a362ffIcon,
.b0cf5b2147254063Adb832b8ebIcon {
  position: absolute;
  top: 2126.5px;
  left: 822px;
  width: 153px;
  height: 120px;
  object-fit: cover;
}
.b0cf5b2147254063Adb832b8ebIcon {
  left: 611px;
  width: 220px;
  height: 287px;
}
.a2e966a7C1f04103947aCae4a7Icon,
.b3650a1b254c38B9d9Ed10b091Icon {
  position: absolute;
  top: 2943.5px;
  left: 776px;
  width: 369px;
  height: 282px;
  object-fit: cover;
}
.b3650a1b254c38B9d9Ed10b091Icon {
  left: 1137px;
  width: 217px;
}
.desktop15Child14,
.desktop15Child15 {
  position: absolute;
  top: 4525px;
  border-radius: var(--br-sm);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
}
.desktop15Child14 {
  left: 177px;
  background-color: var(--color-white);
  width: 331px;
  height: 326px;
}
.desktop15Child15 {
  left: 530px;
  background: linear-gradient(
    -89.72deg,
    rgba(38, 164, 142, 0.1),
    rgba(38, 164, 142, 0)
  );
  width: 734px;
  height: 327px;
}
.firstShoesHeWoreForUnder,
.stephenCurryShoes1 {
  position: absolute;
  top: 154.16px;
  left: 0;
  width: 138.43px;
  height: 107.75px;
  object-fit: cover;
}
.firstShoesHeWoreForUnder {
  top: 0;
  height: 143.93px;
}
.pointsAgainstNewYorkNicks,
.stephenCurryAllStarJersey {
  position: absolute;
  top: 117.98px;
  left: 148.65px;
  width: 110.11px;
  height: 143.94px;
  object-fit: cover;
}
.pointsAgainstNewYorkNicks {
  top: 0;
  height: 107.75px;
}
.stephenCurryShoes1Parent {
  position: absolute;
  top: 4557.25px;
  left: 212.39px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 258.77px;
  height: 261.91px;
}
.ff4b403430c241e98f63D745b1Icon,
.img04741Icon {
  position: absolute;
  top: 2081.5px;
  left: 240px;
  width: 196px;
  height: 309px;
  object-fit: cover;
}
.img04741Icon {
  top: 2370.5px;
  left: 1097px;
  width: 227px;
  height: 296px;
}
.ff5740174c4789a06354bca195Icon,
.img04811Icon {
  position: absolute;
  top: 2608.5px;
  left: 336px;
  width: 229px;
  height: 299px;
  object-fit: cover;
}
.ff5740174c4789a06354bca195Icon {
  top: 3601.5px;
  left: 158px;
  width: 220px;
  height: 286px;
}
.c918ec9eDf824afcAa8eD45968Icon,
.ea5323130452c89ca854167528Icon {
  position: absolute;
  top: 3323.5px;
  left: 158px;
  width: 376px;
  height: 286px;
  object-fit: cover;
}
.ea5323130452c89ca854167528Icon {
  top: 3387.5px;
  left: 918px;
  width: 250px;
  height: 332px;
}
.desktop15Child16 {
  position: absolute;
  top: 121px;
  left: 1453px;
  width: 242px;
  height: 0;
}
.artisticPictureFraming1 {
  position: absolute;
  top: 5433.5px;
  left: 544px;
  font-family: var(--font-old-standard-tt);
  color: var(--color-gray-800);
  text-align: center;
  display: inline-block;
  width: 362px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.aGroup {
  position: absolute;
  top: 5387.5px;
  left: 706px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 38px;
  font-size: var(--font-size-4xl);
  color: var(--color-gray-200);
  font-family: var(--font-mplus-1p);
}
.desktop15Child17 {
  position: absolute;
  top: 5241.5px;
  left: 0;
  background-color: var(--color-teal-100);
  width: 1440px;
  height: 116px;
}
.followUs {
  position: absolute;
  top: 5267.5px;
  left: 1164px;
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-white);
  text-align: right;
  display: inline-block;
  width: 218px;
  height: 30px;
}
.appStorePngLogo331238Icon,
.image69Icon {
  position: absolute;
  top: 5283.5px;
  left: 1250px;
  width: 167px;
  height: 38px;
  object-fit: cover;
}
.appStorePngLogo331238Icon {
  top: 5256.5px;
  left: 38px;
  width: 137px;
  height: 94px;
}
.about,
.careers,
.contact {
  position: absolute;
  text-transform: capitalize;
  display: inline-block;
}
.careers {
  top: 5312.5px;
  left: 660px;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-white);
  width: 127px;
  height: 18px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
}
.about,
.contact {
  top: 5267.5px;
  height: 20px;
}
.about {
  left: 489px;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-white);
  width: 59px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
}
.contact {
  left: 589px;
  width: 76px;
}
.contact,
.privacyPolicy,
.reviews,
.termsOfUse {
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-white);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
}
.termsOfUse {
  position: absolute;
  top: 5267.5px;
  left: 698px;
  text-transform: capitalize;
  display: inline-block;
  width: 127px;
  height: 20px;
}
.privacyPolicy,
.reviews {
  width: 133px;
}
.privacyPolicy {
  position: absolute;
  top: 5312.5px;
  left: 770px;
  text-transform: capitalize;
  display: inline-block;
  height: 18px;
}
.reviews {
  top: 5267.5px;
  left: 866px;
  height: 20px;
}
.allRightsReservedByArtisti,
.blog,
.reviews {
  position: absolute;
  text-transform: capitalize;
  display: inline-block;
}
.blog {
  top: 5312.5px;
  left: 560px;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-white);
  width: 127px;
  height: 18px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
}
.allRightsReservedByArtisti {
  top: 1px;
  left: 12.68px;
  width: 265.32px;
  height: 12px;
}
.logosCopyRightLogoCopyrighIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.68px;
  height: 14px;
  object-fit: cover;
}
.allRightsReservedByArtistiParent {
  position: absolute;
  top: 5449.5px;
  left: 590px;
  width: 278px;
  height: 14px;
  font-size: var(--font-size-xs);
  color: var(--color-gray-900);
  font-family: var(--font-montserrat);
}
.image54Icon,
.image57Icon,
.image72Icon {
  position: absolute;
  top: 184px;
  left: 55px;
  width: 253px;
  height: 301px;
  object-fit: cover;
}
.image57Icon,
.image72Icon {
  top: 792px;
  left: 1079px;
  width: 252px;
  height: 320px;
}
.image57Icon {
  top: 707px;
  left: 790px;
  width: 290px;
  height: 298px;
}
.vectorIcon10,
.vectorIcon11 {
  position: absolute;
  height: 0.26%;
  width: 0.56%;
  top: 85.58%;
  right: 90.69%;
  bottom: 14.17%;
  left: 8.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon11 {
  top: 85.48%;
  right: 8.68%;
  bottom: 14.26%;
  left: 90.76%;
}
.desktop15 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 5470px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-2xl);
  color: var(--color-gray-400);
  font-family: var(--font-open-sans);
}
